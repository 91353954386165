import { msg, str } from '@lit/localize';
import { range } from 'lit/directives/range.js';
import { map } from 'lit/directives/map.js';
import { KeyCodes } from '../../utils/aria';
import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';
import { RAF, isTouch, getOS } from '../../utils';

import styles from './navigation.css';
import logo from '../../../assets/brand/logo.svg';

function template(elem) {
  const {
    os, size, show, selected, selectedItems, mobileMenu, nonav,
  } = elem;

  function renderDownloadButton() {
    function getStoreButton() {
      switch (os) {
        case 'Android':
          return html`<oportun-graphic name="playstore-logo-mark" />`;
        case 'iOS':
          return html`<oportun-graphic name="appstore-logo" />`;
        default:
          return html``;
      }
    }

    const className = (os === 'Android' || os === 'iOS' ? os : 'other').toLowerCase();

    return html`<div class="download appstore ${className}">
      <span>
        <a
          href="https://app.oportun.com/download"
          target="_blank"
          data-event_tracking="link click"
          data-event_tracking_label="Get app"
          data-event_tracking_section="Header"
          >${getStoreButton()} ${msg('Get app')}</a
        ></span
      >
    </div>`;
  }

  function renderMegaMenu() {
    return html`
      <div class="mega-menu">
        <div class="menu-content" @pointerleave=${elem.menuHide}>
          <slot name="menu-${selected}"></slot>
        </div>
        <div class="backdrop"></div>
      </div>
    `;
  }

  function onMenuHover(index, state) {
    return (evt) => {
      evt.stopPropagation();

      elem.menuOpen(evt, evt.currentTarget.parentNode, index);
    };
  }

  function toggleMenu(index, state) {
    return (evt) => {
      evt.stopPropagation();
      if (state) {
        elem.menuHide(evt, evt.currentTarget.parentNode, index);
        elem.menuOpen(evt, evt.currentTarget.parentNode, index);

      } else {
        elem.menuHide(evt, evt.currentTarget.parentNode, index);
      }
    };
  }
  function printElements(){
    return html`
    ${map(
      range(+size),
      (i) => html` <div class="navitem ${selectedItems.has(i + 1) ? 'selected' : ''} ">
                  <div
                    class="item ${selected === i + 1 ? 'selected' : ''}"
                    @pointerenter="${toggleMenu(i + 1, true)}"
                  >
                    <slot name="navitem-${i + 1}" ></slot>
                    <oportun-icon name="${selectedItems.has(i + 1) ? 'arrow-up' : 'arrow-down'}"  style="${ elem.querySelector('[slot="navitem-'+(i + 1)+'"]') ? (elem.querySelector('[slot="navitem-'+(i + 1)+'"]').classList.contains('no-submenu')) ? 'display:none;' : '' : ''} "/>
                  </div>
                </div>`,
    )}
    `
  }
  function renderDesktopNav() {
    return html`
      <div class="navigation">
        <div class="head ${os}">
          <div class="logo">
            <slot name="logo"></slot>
          </div>
          <div class="nav">
           ${ printElements() }
          </div>
          <div class="extras">
            <div class="ctas"><slot name="ctas"></slot></div>
            <div class="langselector">
              <oportun-icon name="globe"></oportun-icon>
              <slot name="langselector"></slot>
            </div>
          </div>
        </div>
        ${show ? renderMegaMenu() : ''}
      </div>
    `;
  }

  function toggleItem(index) {
    return (evt) => {
      elem.toggleItem(evt, evt.currentTarget.parentNode, index);
    };
  }

  function toggleMobileMenu(evt) {
    elem.toggleMobileMenu(evt);
  }

  function renderMobileNav() {
    return html`
      <div class="head ${os}">
        <div class="logo">
          <slot name="logo"></slot>
        </div>
        <div class="actions">
          ${(nonav == "false" ? renderDownloadButton() : '' )}
          <div class="hamburger">
            <span
              @click=${toggleMobileMenu}
              data-event_tracking="link click"
              data-event_tracking_label="Toggle menu"
              data-event_tracking_section="Header"
            >
              <oportun-icon name="hamburger" />
            </span>
          </div>
        </div>
      </div>
      <dialog>
        <div class="navigation mobile">
          <div class="navroot">
            <nav class="nav">
              <div class="nav-container">
                <div class="list">
                  ${map(
    range(+size),
    (i) => html` <div class="navitem ${selectedItems.has(i + 1) ? 'selected' : ''}">
                      <div class="item" @click=${toggleItem(i + 1)}>
                        <slot name="navitem-${i + 1}"></slot>
                        
                        <oportun-icon name="${selectedItems.has(i + 1) ? 'arrow-up' : 'arrow-down'}"  style="${elem.querySelector('[slot="navitem-'+(i + 1)+'"]').classList.contains('no-submenu') ? 'display:none;' : ''}"/>
                      </div>
                      ${selectedItems.has(i + 1)
    ? html`<div class="megamenu"><slot name="menu-${i + 1}"></slot></div>`
    : ''}
                    </div>`,
  )}
                </div>
              </div>
            </nav>
            <div class="ctamobile"><slot name="ctatopmobile"></slot></div>
            <div class="extras">
              <div class="langselector">
                <oportun-icon name="globe"></oportun-icon>
                <slot name="langselector"></slot>
              </div>
              <div class="phone">
                <oportun-icon name="phonecall"></oportun-icon>
                <slot name="phone"></slot>
              </div>
              <div class="locations">
                <oportun-icon name="location"></oportun-icon>
                <slot name="locations"></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="backdrop" @click=${toggleMobileMenu}></div>
      </dialog>
    `;
  }

  return mobileMenu ? renderMobileNav() : renderDesktopNav();
}

export default class Navigation extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    show: { type: 'boolean' },
    selected: { type: 'string' },
    mobileMenu: { type: Boolean },
    size: { type: Number },
    selectedItems: { type: Set },
    os: { type: String },
    nonav: { type: String },
  };

  constructor() {
    super();
    this.selectedItems = new Set([]);
    this.selected = null;
    this.show = false;

    if (window.innerWidth <= 1070) {
      this.mobileMenu = true;
    } else {
      this.mobileMenu = false;
    }
  }

  getSlottedChildren(selector) {
    const slot = this.shadowRoot.querySelector(`slot[name="${selector}"]`);
    return slot.assignedElements({ flatten: true });
  }

  lockScroll() {
    const doc = document.body;
    doc.classList.add('scroll-lock');
  }

  unlockScroll() {
    const doc = document.body;
    doc.classList.remove('scroll-lock');
  }

  toggleMobileMenu(e) {
    const dialog = this.$('dialog');

    const doc = document.body;

    const head = this.$('.head');
    const header = this.parentElement;
    const bounds = head.getBoundingClientRect();

    this.style.setProperty('--dialog-top', `${bounds.bottom + 1}px`);
    dialog.style.setProperty('--dialog-top', `${bounds.bottom + 1}px`);
    dialog.style.setProperty('--dialog-height', `${window.innerHeight - (bounds.bottom + 1)}px`);

    if (dialog.open) {
      dialog.close();
    } else {
      dialog.showModal();

      doc.scrollTop = 1;
      this.lockScroll();

      function closeDialog(e) {
        e.stopPropagation();

        if (e.target === dialog) {
          e.stopPropagation();
          dialog.close();
        }
      }

      const onScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
      };

      const onClose = (e) => {
        dialog.removeEventListener('click', closeDialog);
        dialog.removeEventListener('close', onClose);
        this.unlockScroll();
      };

      dialog.addEventListener('close', onClose);
      dialog.addEventListener('click', closeDialog);
    }

    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: { event: 'Link click', label: 'Toggle menu', section: 'Header' },
    });
  }

  menuHide(e, item, index) {
    const hide = () => {
      this.selected = null;

      if (this.anim) {
        this.anim.cancel();

        const mega = this.renderRoot.querySelector('.mega-menu');
        const anim = mega.animate([{ opacity: 0 }], {
          duration: 400,
          fill: 'both',
        });

        anim.finished.then(() => {
          this.show = false;
          this.anim = null;
          this.exitAnim = null;

          this.triggerEvent('menuhide', { target: this });
        });

        this.exitAnim = anim;
      } else {
        this.show = false;
      }
    };

    this.closeTimer = setTimeout(hide, 200);
  }

  menuOpen(e, item, index) {
    
    if(this.querySelector('[slot="navitem-'+index+'"]').classList.contains('no-submenu')){
      return;
    }

    if (this.closeTimer) {
      clearInterval(this.closeTimer);
    }

    if (this.selected === index && this.show) {
      return;
    }

    let duration = 200;

    this.selected = index;
    if (this.show) {
      duration = 0;
    }
    this.show = true;

    setTimeout(() => {
      const mega = this.renderRoot.querySelector('.mega-menu');
      const anim = mega.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration,
        fill: 'both',
      });
      this.anim = anim;
    });
  }

  toggleItem(e, item, index) {
    e.preventDefault();
    if(this.querySelector('[slot="navitem-'+index+'"]').classList.contains('no-submenu')){
      const goToLink = this.querySelector('[slot="navitem-'+index+'"]').childNodes[1].getAttribute('href');
      window.location.href = goToLink;
      return;
    }
    if (this.selectedItems.has(index)) {
      this.selectedItems.delete(index);
    } else {
      this.selectedItems.add(index);
    }
    this.requestUpdate();
    this.sendAnalyticsEvent({
      target: e.currentTarget,
      originalEvent: e,
      data: { event: 'Link click', label: 'Toggle item', section: 'Header' },
    });
  }

  requestUpdate(name, oldValue) {
    if (name === 'mobileMenu') {
      if (this.mobileMenu) {
        this.classList.add('mobile');
      } else {
        this.classList.remove('mobile');
      }
    }

    return super.requestUpdate(name, oldValue);
  }

  async attachedCallback() {
    this.os = getOS();

    const ro = this.resizeObserver(({ width }) => {
      const dialog = this.$('dialog');
      if (dialog && dialog.open) {
        dialog.close();
      }

      if (this.show) {
        this.show = false;
      }

      if (window.innerWidth <= 1070) {
        this.mobileMenu = true;
      } else {
        this.mobileMenu = false;
      }
    });
    return () => {
      ro.cancel();
    };
  }
}

define('navigation', Navigation);
