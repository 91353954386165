import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';
import { setLocale, getLang } from '../../utils/locale';

import styles from './qrcode.css';

const CACHE = {};

function template(elem) {
  const {
    status, isLoading, errors, urls, width = 150, height = 150, activemobile = 'true', activedesktop = 'true' ,isMobile
  } = elem;

  function renderLoading() {
    if (status === 'loading') {
      return html` <div class="loading">
        <oportun-loading-spinner></oportun-loading-spinner>
      </div>`;
    }
    return '';
  }

  function renderErrors() {
    if (status === 'error') {
      return html` <div class="error">
        ${errors.map((err) => html`<div>${err.message}</div>`)}
      </div>`;
    }
    return '';
  }

  function renderCode() {
    if (status === 'complete') {
      return html`<div class="container">
        ${urls.map(
          (url) => html`<div class="qr ">
                  <oportun-qrcode
                    .url=${url.short_url}
                    .config=${url.config}
                    width=${width}
                    height=${height}
                  ></oportun-qrcode>
                </div>`,
        )}
      </div>`;     
    }
    return '';
  }

  function renderIfActive(){
    if((isMobile == true && activemobile == 'true') || (isMobile == false && activedesktop == 'true')){
      return html`
        <div class="download small" style="min-width: ${width}px;min-height: ${height}px">
          ${renderLoading()} ${renderErrors()} ${renderCode()}
        </div>`;
    }
    else{
      elem.style.height = 0;
      return '';
    }
  }
  return html `${renderIfActive()}`;
  
}

export default class DownloadLink extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    // errors: { type: Array },
    // urls: { type: Array },
    status: { type: String },
    width: { type: Number },
    height: { type: Number },
    isReady: { type: Boolean },
    activemobile: { type: String },
    activedesktop: { type: String },
    isMobile: { type:Boolean },
  };

  constructor() {
    super();
    this.status = 'loading';
  }

  generateFallbackUrl() {
    const baseUrl = new URL('https://app.oportun.com/download');

    // Add tealium id parameters
    baseUrl.searchParams.append('tvid', utag.data.tealium_visitor_id);
    baseUrl.searchParams.append('anonymous_id', utag.data.tealium_visitor_id);

    // Add clean url of current page
    baseUrl.searchParams.append(
      'referring_page',
      encodeURIComponent(teal.data.last_b_object.set_pg_url),
    );

    baseUrl.searchParams.append('utm_source', 'ocw');
    baseUrl.searchParams.append('utm_medium', 'qr');

    const currentURLObject = teal.utils.parseURL(location.href);
    let clean_path = currentURLObject.pathname
      .replace(/^\//, '')
      .replace(/\/$/, '')
      .replace(/\//g, '-');
    if (!clean_path) {
      clean_path = 'homepage';
    }

    baseUrl.searchParams.append('utm_campaign', `ocw_app_download_${clean_path}`);

    return baseUrl.toString();
  }

  generateUrl() {
    const deeplink = sessionStorage.getItem('download_deeplink');

    if (!deeplink) {
      throw new Error('Deeplink missing');
    }

    // const url = [deeplink || this.generateFallbackUrl()];

    const url = [deeplink];

    const lang = getLang();

    const urls = {
      [url]: {
        short_url: '',
        lang,
      },
    };

    return urls;
  }

  async fetchShortUrl(urls) {
    const data = urls;
    console.log('Shortening ', data);

    try {
      const resp = await fetch('/wp-json/ocw/shorten-urls', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const content = await resp.json();

      if (content.errors) {
        const error = new Error('Error fetching qrcodes');
        error.errors = content.errors.map((err) => {
          err;
        });
        throw error;
      }

      const urls = (content.urls || []).map((urlInfo) => ({
        ...urlInfo,

        config: {
          footerHeight: 0,
          headerHeight: 0,
          padding: 20,
          spacing: 20,
        },
      }));

      this.urls = urls;
      this.qrconfig = {};
      // this.status = 'complete';

      return urls;
    } catch (e) {
      this.urls = [];
      console.error(e);

      this.errors = e.errors ? e.errors : [e];
      this.status = 'error';
    }
  }

  getUrl() {
    try {
      const urls = this.generateUrl();

      const url = Object.keys(urls)[0];

      if (CACHE[url]) {
        return CACHE[url];
      }

      const promise = this.fetchShortUrl({ urls }); // do not await here

      CACHE[url] = promise;

      return promise;
    } catch (e) {
      this.status = 'error';
      this.errors = [{ message: 'An error occured. Please try again.' }];
    }
  }

  async attachedCallback() {
    // await sleep(2000);
    // this.status = 'loading';

    const removeListener = () => {
      document.removeEventListener('deep_link_ready', onDeeplinkReady);
    };

    const onDeeplinkReady = async (e) => {
      if (!this.isReady) {
        // only execute once

        this.errors = null;
        this.isReady = true;
        removeListener();

        const urls = await this.getUrl();

        this.urls = urls;
        this.qrconfig = {};
        this.status = 'complete';

        this.style.height = this.height ? `${this.height}px` : '150px';
        this.style.width = this.width ? `${this.width}px` : '150px';
      }
    };
    const ro = new ResizeObserver(entries => {
      entries.forEach(entry => {
          if(entry.contentRect.width < 860){
              this.isMobile = true;

          } else {
              this.isMobile = false;
              
          }
      })
    })

    ro.observe(document.body); 

    document.addEventListener('deep_link_ready', onDeeplinkReady);

    return removeListener;
  }
}

define('download-qrcode', DownloadLink);
