import { styleMap } from 'lit/directives/style-map.js';
import { NorthStarElement, html, define } from '../../northstar';
import { clamp } from '../../utils';
import styles from './slider.css';

function template(elem) {
  const { pages, currentPage, autoscroll } = elem;

  const hasPages = pages.length > 1;
  const hasNextPage = currentPage < pages.length - 1;
  const hasPrevPage = currentPage > 0;


  function renderPagination() {
    return html`<div class="pagination">
      ${hasPages
    ? pages.map(
      (pg) => html`<div
              class="${pg.index === currentPage ? 'selected' : ''}"
              @click=${(e) => elem.setPage(pg, e)}
            ></div>`,
    )
    : ''}
    </div>`;
  }

  function renderLeftArrow() {
    return html`<div
      class="arrow prev ${hasPrevPage ? '' : 'disabled'}"
      @click=${elem.showPreviousPage}
    >
      <oportun-icon name="arrow-left"></oportun-icon>
    </div>`;
  }
  function renderRightArrow() {
    return html`<div
      class="arrow next ${hasNextPage ? '' : 'disabled'}"
      @click=${elem.showNextPage}
    >
      <oportun-icon name="arrow-right"></oportun-icon>
    </div>`;
    
  }
  /*
    @mousedown=${(e) => elem.handleGestureDown(e)} 
    @mouseleave=${elem.handleGestureUp}
    @mouseup=${elem.handleGestureUp}
    @mousemove=${(e) => elem.handleGestureMove(e)}
  */
  if(autoscroll){
      return html`
      <div class="slider autoscroll">
        <div class="slider-scroller"
          @mousedown=${(e) => elem.handleGestureDown(e)} 
          @mousemove=${(e) => elem.handleGestureMove(e)}
          @mouseleave=${elem.handleGestureUp}
          @mouseup=${elem.handleGestureUp}
          @pointerdown=${(e) => elem.handleGestureDown(e)}
          @pointermove=${(e) => elem.handleGestureMove(e)}
          @pointerup=${elem.handleGestureUp}
          @pointerleave=${elem.handleGestureUp}
        >
          <div class="slide">
            <div class="content">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      `;
  }
  else{
    return html`
      <div class="slider">
        <div class="slides">
          ${hasPages ? renderLeftArrow() : ''}
          <div class="content" @scroll=${elem.handleScroll}>
            <slot @slotchange=${elem.handleSlotchange}></slot>
          </div>
          ${hasPages ? renderRightArrow() : ''}
        </div>
        ${hasPages ? renderPagination() : ''}
      </div>
    `;
  }
}

export default class Slider extends NorthStarElement {
  static styles = styles;

  static template = template;

  static properties = {
    currentPage: { type: Number },
    pages: { type: Object },
    autoscroll: { type: String },
    number_of_items: {type: Number},
  };

  constructor() {
    super();
    this.currentPage = 0;
    this.pages = [];

    this.mouseDownLastPositionXSlides = 0;
    this.slidesContainerTransformX = 0;
    this.mouseMoveLastXPositionSlides = 0;
    this.slidesContainer;
    this.increaseValue;
    this.timeoutControll;
    this.mouseDown = false;
  }

  createPages() {
    const items = this.getSlottedChildren();

    const totalWidth = items.reduce((acc, item) => acc + item.offsetWidth, 0);
    this.pageCount = Math.ceil(totalWidth / (this.offsetWidth + 1)); // Math.ceil(items.length / 3);

    this.pages = Array(this.pageCount)
      .fill(null)
      .map((_, index) => ({ index }));
  }

  handleScroll(e) {
    e.stopPropagation();
    const { target } = e;
    // const pg = (this.pages.length - 1) * this.offsetWidth;

    const s = Math.ceil(target.scrollLeft / this.offsetWidth);
    this.currentPage = s;
  }

  handleSlotchange() {
    this.createPages();
  }

  setPage(page, e) {
    const { index } = page;
    const left = this.offsetWidth * index;

    const content = this.renderRoot.querySelector('.content');

    content.scroll({
      top: 0,
      left,
      behavior: 'smooth',
    });
  }

  showPreviousPage(e) {
    const index = clamp(this.currentPage - 1, 0, this.pages.length - 1);
    this.currentPage = index;
    this.setPage({ index });
  }

  showNextPage(e) {
    const index = clamp(this.currentPage + 1, 0, this.pages.length - 1);
    console.log('index', index);
    this.currentPage = index;
    this.setPage({ index });
  }

  handleGestureDown(event){
    this.mouseDown = true;
    const container = this.slidesContainer;
    this.mouseDownLastPositionXSlides = event.pageX;    
  
    const cardContainerTransform = window
      .getComputedStyle(container)
      .getPropertyValue("transform");

    if (cardContainerTransform !== "none") {
      const containerTransformX = parseInt(
        cardContainerTransform.split(",")[4].trim()
      );

      this.slidesContainerTransformX = containerTransformX;
      
    }
    clearInterval(this.timeoutControll);
    
  }

  handleGestureMove(event){
    
    this.mouseDown && this.handleDrag(event.pageX, this.slidesContainer);
	  this.mouseMoveLastXPositionSlides = event.pageX;
    
  }


  handleGestureUp(){
    this.mouseDown = false
    this.slidesContainer.style.cursor = 'grab';
  }

  handleDrag(eventPageX = 0){
    let mouseMoveDifference = 0;
    this.slidesContainer.style.cursor = 'grabbing';
    
    // When eventPageX is provided it means that the dragging action is being handled.
    // Otherwise, the window resize position reset is being handled.
    if (eventPageX) {
      mouseMoveDifference = eventPageX - this.mouseDownLastPositionXSlides;
    }
  
    let translateValueX;

    translateValueX = mouseMoveDifference + this.slidesContainerTransformX;
  
    // Don't allow to drag out ouf left side bounds.
    const hasDraggedOutOfLeftSideBounds = translateValueX > 0;
    if (hasDraggedOutOfLeftSideBounds) {
      translateValueX = 0;
    }
  
    // Don't allow to drag out ouf right side bounds.
    let rightSideFurthestCoordinates;
    rightSideFurthestCoordinates =  this.slidesContainer.offsetX - window.innerWidth;
    // Total card container side margin values.
   
    const cardContainerSideMargin = window.getComputedStyle(this.slidesContainer).getPropertyValue("margin");
    
    const hasDraggedOutOfRightSideBounds =
      Math.abs(translateValueX) >
      rightSideFurthestCoordinates + cardContainerSideMargin;
    if (hasDraggedOutOfRightSideBounds) {
      // The value when dragging left is negative, so it needs to be converted negative here as well.
      translateValueX = -(rightSideFurthestCoordinates + cardContainerSideMargin);
    }
    
    this.increaseValue = (translateValueX * -1);
    this.slidesContainer.style.transform = `translateX(${translateValueX}px)`;
    
  }

  compAutoScroll(){
    this.increaseValue = this.increaseValue + 1;
    
    if((this.increaseValue  )  <=  (this.slidesContainer.offsetWidth * 1.8) ){
      this.slidesContainer.style.transform =  `translateX(-${this.increaseValue}px)`;
    } else{
      this.increaseValue = 0;
      this.slidesContainer.style.transform =  `translateX(0px)`;
    }
  }
  
  async attachedCallback() {
    let timeoutId;
    const onResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        this.createPages();
      }, 1000);
    };

    if(this.autoscroll){
      const container = this;
      
      let slides = container.children;
      const styles = getComputedStyle(slides[0]);
      const totElemns = slides.length;
      /* clone all items to give the illution of infinite carousel */
      container.style.setProperty('--item-width', ('-'+styles.width ) );
      container.style.setProperty('--items-carousel', totElemns );
      
      for(let innerIndex = 0; innerIndex < totElemns; innerIndex++){
        
        const cloneItem = slides[innerIndex].cloneNode(true);
        if(innerIndex === 0){
          cloneItem.id = 'first-clone';
        }
        else if( innerIndex == ( totElemns -1 ) ){
          cloneItem.id = 'last-clone';
        }
        else{
          cloneItem.id = `${innerIndex}-clone`;
        }
        container.append(cloneItem);
      }

      this.conentslider = this.renderRoot.querySelector('.slider.autoscroll');
      this.slidesContainer = this.renderRoot.querySelector('.slider-scroller');      

      this.increaseValue = 0;
      const onmouseup = () => {
        this.mouseDown = false;
        this.timeoutControll = setInterval(() =>{
          this.compAutoScroll()
        },15);
        
      }

      clearTimeout(this.timeoutControll);
      this.timeoutControll = setInterval(() =>{
        this.compAutoScroll()
      },15);
        
      window.addEventListener('mouseup', onmouseup );

    
    const ro = this.resizeObserver(onResize);
    return () => {
      ro.cancel();
    };
  }
}


  
}

define('slider', Slider);
