import { NorthStarElement, html, css,define } from '../../northstar';
import {range} from 'lit/directives/range.js';
import {map} from 'lit/directives/map.js';

import styles from './glossary.css';

function template(elem){
    const { selectedTab, isMobile, items } = elem;

    function renderMobile() {
        
        function renderItem(i) {
            return html`
            <oportun-accordion-item class="item stacked" type="stacked" mode="collapsible" collapsed="${selectedTab === 1 ? false : true}">
                <div class="title" slot="title">
                    <slot name="filter-${i+1}"></slot>
                </div>
                <div class="content">
                    <slot name="term-${i+1}"></slot>
                </div>
            </oportun-accordion-item>`
        }

        return html`
        <oportun-accordion>
            ${map(range(items), renderItem)}
        </oportun-accordion>
        
        
        `;
    }
    
    function renderDesktop() {
        return html`
        <div class="glossary-filters selected-${selectedTab}">
            ${map(range(items), (i) => {
                return html`<div class="filter ${(i+1) === selectedTab ? 'active' : 'inactive' }">
                        <slot name="filter-${i+1}" @click=${(e) => elem.handleSelect(e, i)}></slot>
                    </div>`
            })}
        </div>
        <slot name="term-${selectedTab}"></slot>
        `;
    }
    if(isMobile){
        return html `${renderMobile()}`
    }
    else{
        return html `${renderDesktop()}`
    }
    
}

export default class GlossaryOfTerms extends NorthStarElement {
    static styles = styles;
    
    static template = template;

    static properties = {
        selectedTab: { type: Number},
        isMobile: { type: Boolean },
        items: { type: Number}, 
    }

    constructor() {
        super();
        this.selectedTab = 1;
    }
  
    async attachedCallback() {
        
        const ro = new ResizeObserver(entries => {
            entries.forEach(entry => {
                if(entry.contentRect.width < 768){
                    this.isMobile = true;

                } else {
                    this.isMobile = false;
                    
                }
            })
        })
          
        ro.observe(document.body);
    }
   
    handleSelect(e, i) {
        this.selectedTab = i + 1
    }
}

define('glossary', GlossaryOfTerms);